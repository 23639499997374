//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {Message} from "element-ui";
import {fetchCommissionHRDetail, saveCommissionHR} from "@/api/commission";
import ContractDetail from "@/views/components/ContractDetail";

export default {
	name: 'CommissionDetail',
	components: {
		ContractDetail
	},
	beforeRouteEnter(to, from, next) {
		next(vm => {
			vm.usersOpened = from.fullPath.includes('CheckDocument')
		})
	},
	data() {
		return {
			fields: [
				{key: 'id', label: '', _style: 'width:100px'},
				{key: 'name', label: 'รายการ', _style: 'min-width:100px'},
				{key: 'price', label: 'จำนวนเงิน', _style: 'width: 200px; text-align:right'}
			],
			items: [],
			expenses: [
				{id: '1', name: 'ค่าโอน (ตามใบเสร็จของขนส่ง) 1', price: 0.00, newPrice: 0.00},
				{id: '2', name: 'ค่าโอน (ตามใบเสร็จของขนส่ง) 2', price: 0.00, newPrice: 0.00},
				{id: '3', name: 'ค่าตรวจสภาพรถ', price: 0.00, newPrice: 0.00},
				{id: '4', name: 'ไม่เอารถเข้าตรวจ', price: 0.00, newPrice: 0.00},
				{id: '5', name: 'ค่าบริการขนส่ง', price: 0.00, newPrice: 0.00},
				{id: '6', name: 'ค่าอากรมอบอำนาจ', price: 0.00, newPrice: 0.00},
				{id: '7', name: 'ค่าอากรสัญญา', price: 0.00, newPrice: 0.00},
				{id: '8', name: 'ต่อภาษี', price: 0.00, newPrice: 0.00},
				{id: '9', name: 'พรบ.', price: 0.00, newPrice: 0.00},
				{id: '10', name: 'ตรวจตรอ.', price: 0.00, newPrice: 0.00},
				{id: '11', name: 'ค่าGPS.', price: 0.00, newPrice: 0.00},
				{id: '12', name: 'แจ้งย้าย', price: 0.00, newPrice: 0.00},
				{id: '13', name: 'ฝากตรวจ', price: 0.00, newPrice: 0.00},
				{id: '14', name: 'อื่นๆ', price: 0.00, newPrice: 0.00}
			],
			isLoading: false,
			dialogImageUrl: '',
			dialogVisible: false,
			formData: {
				commission: 0.00,
				oil_cost: 0.00,
				other_cost: 0.00,
				message: '',
				sumPrice: 0.00,
				expenses: []
			},
			balanceFormat: {
				minimumFractionDigits: 2,
				maximumFractionDigits: 2
			},
			largeModal: false,
			tab: 0,
			param: {
				contractId: this.$route.params.id
			},
			onLoad: false
		}
	},
	computed: {
		contractDetail() {
			return this.items;
		},
		sumNewPrice() {
			let sum = 0;
			this.expenses.forEach(item => {
				sum += Number(item.newPrice);
			});
			return sum.toLocaleString('en-US', this.balanceFormat);
		}
	},
	async mounted() {
		this.$store.state.loading = true
		try {
			await this.getCommissionDetail();
		} finally {
			this.$store.state.loading = false
		}
	},
	methods: {
		async getCommissionDetail(){
			await fetchCommissionHRDetail(this.param).then(res =>{
				this.items = []
				const check = res.header;
				const data = res.body;
				if (check.error === 'N') {
					data[0].balance = Number(data[0].balance).toLocaleString('en', this.balanceFormat);
					this.formData.contractId = data[0].contract_id;
					this.formData.contractTypeId = data[0].contract_type_id;
					this.formData.commission = data[0].commission;
					this.formData.oil_cost = data[0].oil_cost;
					this.formData.other_cost = data[0].other_cost;
					this.formData.message = data[0].message;
					this.expenses[0].price = data[0].transfer_fee1 || 0;
					this.expenses[1].price = data[0].transfer_fee2 || 0;
					this.expenses[2].price = data[0].vehicle_inspection_fee || 0;
					this.expenses[3].price = data[0].not_inspecting_fee || 0;
					this.expenses[4].price = data[0].transportation_service || 0;
					this.expenses[5].price = data[0].duty_authorize_fee || 0;
					this.expenses[6].price = data[0].duty_contract_fee || 0;
					this.expenses[7].price = data[0].vehicle_tax || 0;
					this.expenses[8].price = data[0].act_fee || 0;
					this.expenses[9].price = data[0].pvi_fee || 0;
					this.expenses[10].price = data[0].gps_fee || 0;
					this.expenses[11].price = data[0].move_fee || 0;
					this.expenses[12].price = data[0].check_fee || 0;
					this.expenses[13].price = data[0].other || 0;
					this.expenses[0].newPrice = data[0].transfer_fee1 || 0;
					this.expenses[1].newPrice = data[0].transfer_fee2 || 0;
					this.expenses[2].newPrice = data[0].vehicle_inspection_fee || 0;
					this.expenses[3].newPrice = data[0].not_inspecting_fee || 0;
					this.expenses[4].newPrice = data[0].transportation_service || 0;
					this.expenses[5].newPrice = data[0].duty_authorize_fee || 0;
					this.expenses[6].newPrice = data[0].duty_contract_fee || 0;
					this.expenses[7].newPrice = data[0].vehicle_tax || 0;
					this.expenses[8].newPrice = data[0].act_fee || 0;
					this.expenses[9].newPrice = data[0].pvi_fee || 0;
					this.expenses[10].newPrice = data[0].gps_fee || 0;
					this.expenses[11].newPrice = data[0].move_fee || 0;
					this.expenses[12].newPrice = data[0].check_fee || 0;
					this.expenses[13].newPrice = data[0].other || 0;
					let sum = 0;
					this.expenses.forEach(item => {
						sum += item.price;
					});
					this.formData.sumPrice = sum.toLocaleString('en-US', this.balanceFormat);
					this.items = data[0];
				}
			}).catch(error => {
				Message({
					message: 'มีข้อผิดพลาด กรุณาติดต่อผู้ดูแลระบบ',
					type: 'error',
					duration: 5 * 1000,
				})
				this.errorMsg = error
				console.log('error :', error)
			})
		},
		saveData(){
			this.formData.expenses = []
			this.expenses.forEach(item => {
				// sum += item.price;
				this.formData.expenses.push(item.newPrice);
			});
			saveCommissionHR(this.formData).then(response => {
				const check = response.header
				// const data = response.body
				if (check.error === 'N') {
					Message({
						message: 'บันทึกข้อมูลสำเร็จ',
						type: 'success',
						duration: 2 * 1000,
						onClose: () => {
							this.goBack()
						}
					})
				}
			}).catch(error => {
				Message({
					message: 'มีข้อผิดพลาด กรุณาติดต่อผู้ดูแลระบบ',
					type: 'error',
					duration: 3 * 1000,
				})
				this.errorMsg = error
				console.log(error)
			})
		},
		clickTab(tab) {
			console.log('tab = ' + tab)
			this.tab = tab
		},
		validator(val) {
			return val ? val.length >= 4 : false
		},
		goBack() {
			this.usersOpened ? this.$router.go(-1) : this.$router.push({path: '/stepHR/Commission'})
		}
	},
}
